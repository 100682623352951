import { store } from "../../../../../store/index";
import Api from "../../../../../store/Services/Api";

class TrialBalanceReportServices {
  
    getTrialBalanceFilterDatas(param) {
        const url = `api/trial/report/trial-balance/filter`
        const params = {
            'page-index': param.pageIndex,
            'page-reload': param.page_reload,
            'fiscal_year': param.fiscal_year,

        }
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers, params);
    }
  
  

}

export default new TrialBalanceReportServices();