<template>
  <div class="content-body">
    <div class="row mg-0">
      <div class="col-sm-5">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Account Report</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Trial balance
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Trial Balance</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-sm-0 d-flex justify-content-end col-lg-7 p-1">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print"></i>
          </div>
          <!-- <div class="report-tool-icon mr-1">
            <i class="fas fa-download"></i>
          </div> -->
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="row mx-0">
      <table class="table table2">
        <thead>
          <tr>
            <th class="wd-70p table-start-item">Particular</th>
            <th class="wd-15p text-right table-end-item">Dr. Amount</th>
            <th class="wd-15p text-right table-end-item">Cr. Amount</th>
            <th class="scope text-center px-2"></th>
          </tr>
        </thead>
      </table>
    </div>
    <span v-if="!loading && !errorMessage">
      <table
        class="table table4"
        v-for="(trialData, index) in trialReportData"
        :key="trialData.id"
      >
        <thead>
          <tr>
            <th class="wd-70p table-start-item">{{ trialData.title }}</th>
            <th class="wd-15p text-right table-end-item">
              <span v-if="trialData.cr == 0">
                {{ parseDigitForSlip(trialData.total_closing) }}
              </span>
              <span v-else>-</span>
            </th>
            <th class="wd-15p text-right table-end-item">
              <span v-if="trialData.cr == 1">
                {{ parseDigitForSlip(trialData.total_closing) }}
              </span>
              <span v-else>-</span>
            </th>
            <th class="scope text-center px-1">
              <a
                data-toggle="collapse"
                :href="'#collapseId' + index"
                role="button"
                arial-control="collapseExample"
                v-if="trialData.accounts.length > 0"
                ><i class="fas fa-caret-square-down"></i
              ></a>
            </th>
          </tr>
        </thead>
        <tbody class="collapse" :id="'collapseId' + index">
          <tr v-for="account in trialData.accounts" :key="account.slug">
            <td class="table-start-item pl-4">{{ account.account_name }}</td>
            <td class="text-right table-end-item">
              <span v-if="trialData.cr == 0">
                {{ parseDigitForSlip(account.closing_balance) }}
              </span>
              <span v-else>-</span>
            </td>
            <td class="text-right table-end-item">
              <span v-if="trialData.cr == 1">
                {{ parseDigitForSlip(account.closing_balance) }}
              </span>
              <span v-else>-</span>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table table2">
        <thead>
          <tr>
            <th class="wd-70p table-start-item">
              Difference in Opening balance
            </th>
            <th class="wd-15p text-right table-end-item">
              <span v-if="totalDifference < 0"
                >{{ Math.abs(parseDigitForSlip(totalDifference)) }}
              </span>
            </th>
            <th class="wd-15p text-right table-end-item">
              <span v-if="totalDifference > 0"
                >{{ parseDigitForSlip(totalDifference) }}
              </span>
            </th>
            <th class="scope text-center px-2"></th>
          </tr>
        </thead>
      </table>
      <table class="table table2">
        <thead>
          <tr>
            <th class="wd-70p table-start-item">Total</th>
            <th class="wd-15p text-right table-end-item">Total Dr. Amount</th>
            <th class="wd-15p text-right table-end-item">Total Cr. Amount</th>
            <th class="scope text-center px-2"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="wd-70p table-start-item"></td>
            <td class="wd-15p text-right table-end-item">
              {{ parseDigitForSlip(totalDebitAmount) }}
            </td>
            <td class="wd-15p text-right table-end-item">
              {{ parseDigitForSlip(totalCreditAmount) }}
            </td>
            <td class="scope text-center px-2"></td>
          </tr>
        </tbody>
      </table>
    </span>
    <span v-else-if="loading" class="loading-css">Loading...</span>
    <span v-else-if="!loading && errorMessage" class="loading-css">{{
      errorMessage
    }}</span>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";

export default {
  data() {
    return {
      sessionYears: [],
      totalCreditAmount: 0,
      totalDebitAmount: 0,
      totalDifference: "",
      trial_wise_params: {
        pageIndex: "all",
        branch: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        from_date: "",
        to_date: "",
        searched: "",
        offset: 0,
        fiscal_year: "2021-22",
      },
      errorMessage: "",
      loading: false,
      error: false,
      totalPageCount: 0,
      pageReload: "",
      pageSerialNo: 1,
      page: 1,
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "dataLists4",
      "dataLists9",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("trialReport", ["trialReportData"]),
  },
  beforeMount() {
    this.getFilteredDataWithoutDates();
    this.pageReload = true;
  },
  methods: {
    // today/month section
    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.trial_wise_params.pageIndex,
        branch: this.trial_wise_params.branch,
        today: this.trial_wise_params.day,
        month: this.trial_wise_params.month + 1,
        year: this.trial_wise_params.year,
        from_date: this.trial_wise_params.from_date,
        to_date: this.trial_wise_params.to_date,
        searched: this.trial_wise_params.searched,
        offset: this.trial_wise_params.offset,
        fiscal_year: this.trial_wise_params.fiscal_year,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.trial_wise_params.pageIndex,
        branch: this.trial_wise_params.branch,
        today: "",
        month: "",
        year: this.trial_wise_params.year,
        from_date: this.trial_wise_params.from_date,
        to_date: this.trial_wise_params.to_date,
        searched: this.trial_wise_params.searched,
        offset: this.trial_wise_params.offset,
        fiscal_year: this.trial_wise_params.fiscal_year,
      };
      this.ajaxRequest(params);
    },

    ajaxRequest(params) {
      this.error = false;
      Services.getTrialBalanceFilterDatas(params)
        .then((res) => {
          this.$store.commit(
            "trialReport/setTrialReportData",
            res.data.data.trial_report
          );
          this.totalCreditAmount = res.data.data.total_credit_amount;
          this.totalDebitAmount = res.data.data.total_debit_amount;
          this.totalDifference = res.data.data.difference;
        })
        .catch((error) => {
          this.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.loading-css {
  background-color: #8db8ee;
  text-align: center;
  width: 100%;
}
</style>